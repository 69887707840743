import { AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
import { ICourse, ICourseRecord } from '@type/course.type';
import {
  UpdateNowCourseTeacherPayload,
  GetCourseRecordsPayload,
  GetCourseListForStudentSchedulePayload,
  CancelCoursePayload,
  CancelBulkCoursePayload,
  CheckCourseSchedulePayload,
  IGetCourseListParams,
  IGetCourseListResponse,
  Courses,
} from './course.type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  /* 編輯 NowCourse 老師 */
  updateNowCourseTeacher({
    id,
    ...data
  }: UpdateNowCourseTeacherPayload): TApiResponse<any> {
    return axios.patch(`${apiPrefix}/now-course/${id}/teacher`, data);
  },
  /**
   * 課程修改記錄列表
   */
  getCourseRecords({
    id,
    ...params
  }: GetCourseRecordsPayload): TApiResponse<ICourseRecord[]> {
    return axios.get(`${apiPrefix}/course/${id}/records`, { params });
  },
  /**
   *
   * @returns 不可被排班的老師
   * @description 從 startAt 到 endAt，找出 teacherIds 中，不可被排班的老師
   */
  recreateCourseSchedule(payload: {
    teacherIds: ObjectId[];
    startAt: ISOString;
    endAt: ISOString;
  }): TApiResponse<ObjectId[]> {
    return axios.post(`${apiPrefix}/course/re-create-course-schedule`, payload);
  },
  getCourseListForStudentSchedule(
    payload: GetCourseListForStudentSchedulePayload,
  ): TApiResponse<ICourse[]> {
    return axios.get(`${apiPrefix}/course/course-schedule`, {
      params: payload,
    });
  },

  /**
   * 取得 課程列表
   */
  getCourseList(
    params: IGetCourseListParams,
  ): TApiResponse<IGetCourseListResponse> {
    return axios.get(`${apiPrefix}/course/findAllUseAggregate`, { params });
  },
  /**
   * 取得 課程資訊
   */
  getCourseUseAggregateById(id: string): TApiResponse<Courses> {
    return axios.get(`${apiPrefix}/course/UseAggregate/${id}`);
  },
  /**
   * 取得 單一課程資料
   */
  getCourseById(id: string): TApiResponse<ICourse> {
    return axios.get(`${apiPrefix}/course/${id}`);
  },
  /**
   * 取消 課程
   */
  cancelCourse(id: string, data: CancelCoursePayload): TApiResponse<any> {
    return axios.patch(`${apiPrefix}/course/${id}/cancel`, data);
  },
  /**
   * 取消 試聽課程
   */
  cancelAuditionCourse(
    id: string,
    data: CancelCoursePayload,
  ): TApiResponse<any> {
    return axios.patch(`${apiPrefix}/course/${id}/cancel-audition`, data);
  },
  /**
   * 多筆取消課程
   */
  cancelBulkCourse(data: CancelBulkCoursePayload): TApiResponse<ICourse> {
    return axios.patch(`${apiPrefix}/course/cancel-bulk`, data);
  },
  /**
   * 修正課程的 schedule
   */
  checkCourseSchedule(data: CheckCourseSchedulePayload): TApiResponse<any> {
    return axios.post(`${apiPrefix}/course/check-course-schedule`, data);
  },
});
